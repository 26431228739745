@tailwind base;
@tailwind components;
@tailwind utilities;


li {
    transition: all .7s ease;
}

li:hover {
    background-color: rgb(220, 220, 220);
}

.textShadow {
    text-shadow: 3px 3px 4px black;
}

button {
    transition: all .7s ease;
}

button:hover {
    background-color: rgb(255, 146, 50);
}

.orangeBG {
    background-color: rgb(255, 136, 0);
}

.grow:hover { 
    transform: scale(1.1);
    background-color: rgba(0,0,0,0) 
}
    
.link {
    color: blue;
}

.link:hover {
    color: rgb(0, 100, 250);
    text-decoration: underline;
}

.loop-text {
    animation: loopText 10s infinite linear;      
}

@keyframes loopText {

  from {
      transform: translateX(0);
   }

  to {
      transform: translateX(-110%);
   }

}